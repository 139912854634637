import React from "react";
import Reading from "../types/Reading";
import formatDate from "../config/formatDate";
import "./Status.scss";

export default function Status({
  title,
  data,
  color,
}: {
  title: string;
  data: Reading;
  color: string;
}) {
  return (
    <div className="status" style={{ backgroundColor: color }}>
      <small>{formatDate(new Date(data.at))}</small>
      <h3>{(data.temperature ?? 0).toFixed(1)}&deg;C</h3>
      <p>{data.humidity ?? 0}%</p>
      <h2 style={{ color }}>{title}</h2>
    </div>
  );
}
