import React from "react";
import colors from "../config/colors";
import Reading from "../types/Reading";
import Status from "./Status";
import "./Latest.scss";

export default function Latest({
  titles,
  data,
}: {
  titles: { [key: string]: string };
  data: { [key: string]: Reading };
}) {
  return (
    <div className="latest">
      {Object.keys(titles).map((key, i) => (
        <Status
          key={key}
          title={titles[key]}
          data={data[key]}
          color={colors[i]}
        />
      ))}
    </div>
  );
}
