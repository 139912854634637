import React from "react";
import useReadings from "./hooks/useReadings";
import Graph from "./components/Graph";
import Latest from "./components/Latest";
import formatDate from "./config/formatDate";
import "./App.scss";

function App() {
  const { titles, loading, error, readings, latest } = useReadings();

  return (
    <div className="App">
      {latest && titles && <Latest titles={titles} data={latest} />}
      {readings && titles && <Graph titles={titles} data={readings} />}
      <table>
        {latest &&
          Object.keys(latest).map((key) => (
            <tr>
              <td>{(titles && titles[key]) ?? "ukjent"}</td>
              <td align="right">{key}</td>
              <td>{formatDate(new Date(latest[key].at))}</td>
              <td align="right">{latest[key].temperature?.toFixed(1)}&deg;C</td>
              <td align="right">{latest[key].humidity}%</td>
            </tr>
          ))}
      </table>
      {loading && <div className="loading">Loading</div>}
      {error && <div className="error">Load error!</div>}
    </div>
  );
}

export default App;
