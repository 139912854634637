import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Reading from "../types/Reading";
import titles from "../config/titles";

export default function useReadings() {
  const [readings, setReadings] = useState<Reading[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    axios
      .get("/api/readings")
      .then((res) => {
        setLoading(false);
        setReadings(res.data);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        setLoading(false);
      });
  }, []);

  const { latest, data } = useMemo(() => {
    if (!readings) return {};

    const data: { [key: string]: Reading[] } = {};
    const latest: { [key: string]: Reading } = {};

    for (const reading of readings) {
      const id = `${reading.prefix}.${reading.sensor}`;

      if (!data[id]) {
        data[id] = [];
      }

      data[id].push(reading);
      latest[id] = reading;
    }

    for (const id of Object.keys(data)) {
      data[id] = data[id].map((r) => ({ ...r, at: new Date(r.at).getTime() }));
    }

    const dayAgo = new Date();
    dayAgo.setDate(dayAgo.getDate() - 1);
    dayAgo.setMinutes(0, 0, 0);
    const delta = 15 * 60 * 1000;

    for (const id of Object.keys(data)) {
      const d: Reading[] = [];
      let t = dayAgo.getTime();
      let temp = 0;
      let hum = 0;
      let n = 0;
      for (const reading of data[id]) {
        while (t + delta < reading.at) {
          d.push({
            at: t,
            temperature: n > 0 ? temp / n : null,
            humidity: n > 0 ? hum / n : null,
            prefix: reading.prefix,
            sensor: reading.sensor,
          });
          n = 0;
          temp = 0;
          hum = 0;
          t += delta;
        }
        temp += reading.temperature ?? 0;
        hum += reading.humidity ?? 0;
        ++n;
      }
      data[id] = d;
    }

    return { data, latest };
  }, [readings]);

  return {
    loading,
    readings: data,
    latest,
    titles:
      latest &&
      Object.keys(titles)
        .filter((key) => Object.keys(latest).includes(key))
        .reduce(
          (o, key) => ({ ...o, [key]: titles[key] }),
          {} as { [key: string]: string }
        ),
    error,
  };
}
