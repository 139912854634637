import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import colors from "../config/colors";
import formatDate from "../config/formatDate";
import Reading from "../types/Reading";
import "./Graph.scss";

export default function Graph({
  data,
  titles,
}: {
  data: { [key: string]: Reading[] };
  titles: { [key: string]: string };
}) {
  return (
    <div className="graph">
      <h3>Temperatur</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart>
          <CartesianGrid />
          <ReferenceLine y={0} stroke="red" strokeDasharray="2 3" />
          <YAxis
            domain={[-15, 35]}
            unit="&deg;C"
            ticks={[-10, 0, 10, 20, 30]}
          />
          <XAxis
            dataKey="at"
            type="number"
            tickFormatter={formatDate}
            domain={["dataMin", "dataMax"]}
            scale="time"
            angle={-15}
            dx={-40}
            dy={10}
            height={40}
          />
          {Object.keys(titles).map(
            (key, i) =>
              data[key].length > 0 && (
                <Line
                  animateNewValues={false}
                  isAnimationActive={false}
                  key={key}
                  dataKey="temperature"
                  name={titles[key]}
                  stroke={colors[i]}
                  strokeWidth="2"
                  data={data[key]}
                  dot={false}
                  connectNulls={false}
                />
              )
          )}
          <Tooltip
            labelFormatter={(label) => formatDate(new Date(label))}
            formatter={(value: number) => value.toFixed(1)}
          />
        </LineChart>
      </ResponsiveContainer>
      <h3>Luftfuktighet</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart>
          <CartesianGrid />
          <YAxis domain={[0, 100]} unit="%" />
          <XAxis
            dataKey="at"
            type="number"
            tickFormatter={formatDate}
            domain={["dataMin", "dataMax"]}
            scale="time"
            angle={-15}
            dx={-40}
            dy={10}
            height={40}
          />
          {Object.keys(titles).map(
            (key, i) =>
              data[key].length > 0 && (
                <Line
                  animateNewValues={false}
                  isAnimationActive={false}
                  key={key}
                  dataKey="humidity"
                  name={titles[key]}
                  stroke={colors[i]}
                  strokeWidth="2"
                  data={data[key]}
                  dot={false}
                  connectNulls={false}
                />
              )
          )}
          <Tooltip
            filterNull
            shared={false}
            labelFormatter={(label) => formatDate(new Date(label))}
            formatter={(value: number) => value.toFixed(0)}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
